<template>
<v-col class="align-self-center">
    <v-row no-gutters justify="end" class="d-md-none v-primary-base">
        <a class="d-flex flex-column justify-center align-center mr-3" @click="openLanguageDialog()">
            <i class="fas fa-language toolbar-icon"></i>
            <!-- <div class="forum-icon" /> -->
            <div class="text-caption toolbar-caption">{{ $t(`label.language`) }}</div>
        </a>
        <a class="d-flex flex-column justify-center align-center" @click="this.openLogin">
            <i class="fas fa-sign-in-alt toolbar-icon"></i>
            <!-- <div class="service-icon"></div> -->
            <div class="text-caption toolbar-caption">{{ $t(`label.login`) }}</div>
        </a>
        <!-- <v-col class="hidden-md-and-up" v-show="$route.path!=='/register-mobile'">
            <v-btn @click="this.openLogin" color="black" class="mr-1 primary-button embedded-mobile-login-button font-weight-bold yellow--text">{{ $t(`button.login`) }}
            </v-btn>
            <app-button :customClass="'buttonPrimary theme-button embedded-mobile-register-button'" :action="this.openRegister" :title="$t(`label.signUp`)"></app-button>
        </v-col> -->
    </v-row>

    <v-row no-gutters justify="end" class="hidden-sm-and-down">
        <v-btn @click="this.openLogin" class="mr-6 embedded-login-button text-capitalize">{{ $t(`button.login`) }}</v-btn>
        <v-btn @click="this.openRegister" class="mr-6 embedded-register-button white--text text-capitalize">{{ $t(`button.register`) }}</v-btn>
        <!-- <app-button :customClass="'buttonPrimary embedded-register-button'" :action="this.openRegister" :title="$t(`label.signUp`)"></app-button> -->
        <!-- <v-btn class="pt-0 ml-6 align-self-center" icon small @click="openLanguageDialog()" v-if="!hideLanguage">
            <v-avatar size="48" class="language-button">
                <img :src="`/static/image/country/${currentCurrency}.svg`" />
            </v-avatar>
        </v-btn> -->
    </v-row>
</v-col>
</template>

<script>
import stingFormat from 'string-format'
import {
    formValidator,
    uiHelper
} from '@/util'
import {
    SHARED
} from '@/constants/constants'
import {
    MEMBER_LOGIN
} from '@/store/member.module'
import {
    ROUTE_NAME
} from '@/constants/route.constants'
export default {
    name: 'appLoginEmbedded',
    props: {
        openRegisterDialog: {
            type: Function,
            required: true,
            default: () => {}
        },
        openLoginDialog: {
            type: Function,
            required: true,
            default: () => {}
        },
        openLanguageDialog: {
            type: Function,
            required: true,
            default: () => {}
        },
        hideLanguage: {
            type: Boolean,
            require: false,
            default: false
        }
    },
    data: () => ({
        validator: formValidator,
        stingFormat: stingFormat,
        routeName: ROUTE_NAME,
        retainSignIn: false,
        loginObj: {
            memberCode: '',
            password: '',
            domain: '',
            platform: '',
            option: SHARED.DEFAULT_LOGIN_PASSWORD_METHOD
        },
        currentCurrency: uiHelper.getCurrency(),
        currentLanguage: uiHelper.getLanguage(),
        items: [{
                title: 'Bengali',
                key: 'bd'
            },
            {
                title: 'English',
                key: 'en'
            },
            {
                title: 'Tamil',
                key: 'ta'
            }
        ]
    }),
    computed: {
        isLoggedIn() {
            return this.$store.state.member.isLoggedIn
        }
    },
    watch: {
        isLoggedIn() {
            // this.$refs.loginForm.reset()
        }
    },
    methods: {
        openRegister() {
            // this.openRegisterDialog()
            this.$router.push({name: ROUTE_NAME.REGISTER, params:{ lang: this.$route.params.lang}})
            // this.$router.push({
            //     name: ROUTE_NAME.REGISTER
            // })
        },
        openLogin() {
            this.openLoginDialog()
        },
        changeLanguage(language) {
            this.$store.dispatch(`${SHARED.LOADING}`)
            uiHelper.removeCookie('language')
            uiHelper.setCookie('language', language)
            window.location.reload()
        }
    }
}
</script>

<style lang="scss">
.login-form .input-field .v-input__control .v-input__slot .v-text-field__slot .v-label {
    line-height: 15px;
    text-transform: capitalize !important;
}

.language-button-div {
    line-height: 15px;
    min-height: 40px !important;
}

.embedded-login-button {
    height: 1.5rem;
    width: 120px;
    background-color: var(--v-secondary-base) !important;
    //border: 1px solid #ffffff;
    color: #ffffff !important;
}

.embedded-login-button:hover {
    border: 1px solid var(--v-secondary-base);
}

.embedded-register-button {
    height: 28px;
    width: 120px;
    border-radius: 4px;
    background: var(--v-secondary-base) !important;
    box-shadow: 0 2px 8px 0 rgb(6, 8, 15, 0.1), inset 0 0 3px 0 #ffdd8f;
    transition: box-shadow .5s ease-out .1s, background-position .3s ease-in, text-shadow .3s ease-in .1s;

    .v-btn::before {
        background-color: transparent;
    }
}

.embedded-register-button:hover {
    transition-duration: 0.6s;
    transition-delay: 0.3s;
    background-position: 50% 0;
    box-shadow: 0 4px 8px 1px rgb(6, 8, 15, 1),
}

.forgot-button {
    position: absolute;
    top: 65px;
    right: 37%;
}

.register-button {
    min-width: 120px;
}

.toolbar-icon {
    color: var(--v-text_reverse-base);
    font-size: 1.1rem;
}

.toolbar-caption {
    color: var(--v-text_reverse-base);
    line-height: 0.8rem;
}

.forum-icon {
    width: 24px;
    height: 24px;
    background-color: var(--v-text_reverse-base);
    -webkit-mask: url(/static/svg/header-forum-icon.svg);
    mask: url(/static/svg/header-forum-icon.svg);
    mask-repeat: no-repeat;
    mask-position: center;
}

.service-icon {
    width: 24px;
    height: 24px;
    background-color: var(--v-text_reverse-base);
    -webkit-mask: url(/static/svg/header-service-icon.svg );
    mask: url(/static/svg/header-service-icon.svg);
    mask-repeat: no-repeat;
    mask-position: center;
}

@media (max-width: 959px) {
    .embedded-mobile-login-button {
        width: 70px !important;
        height: 30px !important;
        font-size: 12px !important;
    }

    .embedded-mobile-register-button {
        width: 98px !important;
    }
}
</style>
