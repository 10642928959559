<template>
  <v-row no-gutters>
    <v-col cols="12" class="px-8">
      <v-form ref="resetPasswordForm">
        <app-form-field
          :enabledLabel=true
          :hint="resetPasswordMemberCode"
          :label="$t(`field.username`)"
          :placeholder="$t(`fieldHint.username`)"
          :readonly="true"
          :value="resetPasswordMemberCode"
          :custom-class="'style_membercode'"
        ></app-form-field>
        <app-form-field
          v-model.trim="resetPasswordObj.newPassword"
          :enabledLabel=true
          :label="$t(`field.newPassword`)"
          :placeholder="$t(`fieldHint.password`)"
          :rules="validator.passwordRules()"
          type="password"
        ></app-form-field>
        <app-form-field
          v-model.trim="resetPasswordObj.confirmNewPassword"
          :enabledLabel=true
          :label="$t(`field.confirmNewPassword`)"
          :placeholder="$t(`fieldHint.password`)"
          :rules="validator.confirmPasswordRules(resetPasswordObj.newPassword, resetPasswordObj.confirmNewPassword)"
          type="password"
        ></app-form-field>
        <v-row no-gutters>
          <v-col cols="12">
            <app-button class="dialog-button" :action="this.resetPassword" :title="$t(`button.submit`)"
                        :custom-class="'full-width color-bg-white-text'"></app-button>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { formValidator } from "@/util";
import { MEMBER_RESET_PASSWORD, MEMBER_UPDATE_FORGOT_PASSWORD } from "@/store/member.module";

export default {
  name: "appResetPassword",
  props: {
    resetPasswordMemberCode: {
      type: String,
      default: ""
    },
    resetPasswordContact: {
      type: String,
      default: ""
    }
  },
  mounted() {
    this.$refs.resetPasswordForm.reset();
  },
  data: () => ({
    validator: formValidator,
    retainSignIn: false,
    resetPasswordObj: {
      memberCode: "",
      contact: "",
      newPassword: "",
      confirmNewPassword: ""
    }
  }),
  methods: {
    resetPassword() {
      if (this.$refs.resetPasswordForm.validate()) {
        let data = this.resetPasswordObj;
        data.memberCode = this.resetPasswordMemberCode;
        data.contact = this.resetPasswordContact;
        this.$store.dispatch(`${MEMBER_UPDATE_FORGOT_PASSWORD}`, { data });
      }
    }
  }
};
</script>

<style lang="scss">
.v-text-field--outlined .v-label {
  top: 10px;
}

.color-bg-white-text {
  color: white !important;
  background-color:var(--v-primary-base) !important;
}
</style>
