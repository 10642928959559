<template>
    <v-row no-gutters>
        <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' :'px-5'">
            <v-form ref="editPersonalForm">
                <app-form-field :enabledLabel=true v-model="personalInfo.email.value" :value="personalInfo.email.value"  :label="$t(`field.email`)" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.emailRules()"></app-form-field>
                <app-form-field :enabledLabel=true :value="personalInfo.memberCode" :label="$t(`field.username`)" :readonly="true"></app-form-field>
                 <v-row no-gutters justify="space-between">
                <v-col cols=12>
                <label style="float:left" class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                    {{ $t(`field.dateOfBirth`) }}<span class="red--text ml-1">*</span></label>
                </v-col>
                 </v-row>
                 <v-row no-gutters justify="space-between">
                <v-col cols=12>
                <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="$t(`fieldHint.ddmmyy`)"
                    append-icon="mdi-calendar"
                    outlined
                    class="input-field elevation-0 hide-details"
                    color="buttonPrimary"
                    v-on="on"
                    :value="personalInfo.dateOfBirth | shortDate"
                    :disabled="personalInfo.dateOfBirth ? true : false"
                    :rules="validator.dateRules()"
                  ></v-text-field>
                  <!-- <app-form-field :enabledLabel=true v-model.trim="loginObj.memberCode" :label="$t(`field.dateOfBirth`)" 
                  :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.dateRules()" :icon="'event'"
                  v-on="on"></app-form-field> -->
                </template>
                <v-date-picker
                  class="custom-date-picker"
                  ref="dateOfBirthPicker"
                  v-model="personalInfo.dateOfBirth"
                  scrollable
                  color="primary"
                  :max="dateOfBirthMaxDate"
                  @input="dateOfBirthMenu = false"
                  :locale="language"
                  ></v-date-picker>
              </v-menu>
                </v-col>
                 </v-row>
                <app-form-field :enabledLabel=true :value="mobilePrefix + ' ' +personalInfo.mobile.value" :label="$t(`field.phoneNumber`)" :readonly="true"></app-form-field>

                <app-form-field v-if="memberInfo.name&&memberInfo.name!=''" :enabledLabel=true v-model="personalInfo.name" :value="personalInfo.name" :label="$t(`field.fullname`)" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules()" :readonly="true"></app-form-field>
                <app-form-field v-else :enabledLabel=true v-model="personalInfo.name" :value="personalInfo.name" :label="$t(`field.fullname`)" :placeholder="$t(`fieldHint.fillUpHere`)" :rules="validator.requiredRules()" ></app-form-field>
                <v-row no-gutters class="pb-10">
                    <v-col cols="12">
                        <app-button class="dialog-button" :action="this.submit" :title="$t(`button.submit`)"></app-button>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
    import find from 'lodash/find'
    import stingFormat from 'string-format'
    import {
        formValidator,
        uiHelper,
        errorCodeHelper,
        locale
    } from '@/util'
    import { MEMBER_UPDATE_DETAIL,MEMBER_DETAIL } from '@/store/member.module'
    export default {
        name: 'profileEdit',
        mounted() {
            //this.$refs.editPersonalForm.reset()
        },
        created(){
          this.metaTag()
            this.blockFieldUpdate.mobile = this.personalInfo.mobile.value ? true:false;
            this.blockFieldUpdate.email = this.personalInfo.email.value ? true:false;
            
        },
        props: {
            closeAction: {
                type: Function,
                required: true,
                default: () => {}
            },
            personalInfo: {
                type: Object,
                require: true,
                default: {}
            }
        },
        data: () => ({
            blockFieldUpdate:{
                mobile:false,
                email:false,
            },
            dateOfBirthMaxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().substr(0, 10),
            dateOfBirthMenu: false,
            language: uiHelper.getLanguage(),
            validator: formValidator,
            stingFormat: stingFormat,
            mobileNumberPrefixList: [
            {
                text: "+91",
                value: "INR"
            },
            {
                text: "+880",
                value: "BDT"
            }
            ]
        }),
        computed: {            
            isLoggedIn() {
                return this.$store.state.member.isLoggedIn
            },
            memberInfo() {
                return this.$store.state.member.info
            },
            mobilePrefix() {
                let self = this
                let prefix = find(this.mobileNumberPrefixList, function(p){
                                return p.value == self.personalInfo.currency
                            })
                if(prefix)
                    return prefix.text
                else
                    return ''
            }
        },
        watch: {
            dateOfBirthMenu(val) {
                val && setTimeout(() => (this.$refs.dateOfBirthPicker.activePicker = 'YEAR'))
            }
        },
        methods: {
          metaTag() {
            const currentUrl = window.location.href;
            uiHelper.setCanonical(currentUrl)
            const domainWithProtocol = currentUrl.split('/')[2];
            const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
            const path = window.location.pathname;
            const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '');
            const Links = `https://${domain}${cleanPath}`;
            const Linksen = `https://${domain}/en-BD${cleanPath}`;
            const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
            uiHelper.setHreflangLinks(Links)
            uiHelper.setHreflangLinksen(Linksen)
            uiHelper.setHreflangLinksbd(Linksbn)
          },
            submit() {
                if (this.$refs.editPersonalForm.validate()) {
                    this.confirmationDialog()
                }
            },
            updateMember(){
                let memberObj ={};// this.personalInfo
                memberObj.currency = this.personalInfo.currency;
                memberObj.dateOfBirth = this.personalInfo.dateOfBirth;
                memberObj.memberCode = this.personalInfo.memberCode;
                memberObj.name = this.personalInfo.name;
                memberObj.secondaryMobile = this.personalInfo.secondaryMobile;

                if(!this.blockFieldUpdate.mobile){
                    memberObj.mobile=this.personalInfo.mobile;
                }

                if(!this.blockFieldUpdate.email){
                    memberObj.email=this.personalInfo.email;
                }
                this.$store.dispatch(`${MEMBER_UPDATE_DETAIL}`, { memberObj })
                this.$store.dispatch(`${MEMBER_DETAIL}`)
                this.closePageDialog()
                this.closeAction()
            },
            confirmationDialog() {
                let dialog = this.initializePageDialogProperty()
                dialog.title = locale.getMessage(`label.updateMemberDetail`)
                dialog.message.push(locale.getMessage(`message.confirmUpdateProfile`))
                dialog.button.push({
                    title: locale.getMessage(`button.submit`),
                    action: () => this.updateMember()
                })
                this.openPageDialog(dialog)
            },
            initializePageDialogProperty() {
                let initPageDialog = {
                    title: locale.getMessage(`label.system`),
                    dialogXButton: this.closePageDialog,
                    messageTitle: '',
                    message: [],
                    button: []
                }
                return initPageDialog
            },
            openPageDialog(dialog) {
                this.$eventHub.$emit('open-page-dialog', dialog)
            },
            closePageDialog() {
                this.$eventHub.$emit('close-page-dialog', true)
            },
        }
    }
</script>

<style lang="scss">
//.v-date-picker-table td {
//  padding: 0px;
//}
.custom-date-picker .v-date-picker-table td {
  padding: 0px !important;
}
</style>
