<template>
  <div>
    <v-row no-gutters class="register_panel-af" :class="$vuetify.breakpoint.mdAndDown ? '' : ''">
      <v-row no-gutters class="register-wrapper-af" :class="$vuetify.breakpoint.mdAndDown ? '' : 'px-5 '">
        <v-col class="no-gutters">
          <v-window v-model="step" :touchless="true">
            <v-window-item :value="1" :key="1">
              <v-form ref="registerForm1">
                <v-card-text class="ma-0 pa-0">
                  <app-form-field
                    class="registerformfield"
                    :enabledLabel="true"
                    v-model.trim="registerObj.memberCode"
                    :label="$t(`field.username`)"
                    :placeholder="$t(`fieldHint.username`)"
                    key="username"
                    :errorMsg="userNameErrorMsg"
                    :blur="checkUsername"
                    :rules="validator.usernameRules()"
                  ></app-form-field>
                  <app-form-field
                    class="registerformfield"
                    :enabledLabel="true"
                    v-model.trim="registerObj.password"
                    :label="$t(`field.password`)"
                    :placeholder="$t(`fieldHint.password2`)"
                    :rules="validator.passwordRules()"
                    :allowShowPassword="false"
                    type="password"
                    prependInnerIcon="mdi-lock-outline"
                  ></app-form-field>
                  <app-form-field
                    class="registerformfield"
                    :enabledLabel="true"
                    v-model.trim="confirmNewPassword"
                    :label="$t(`field.confirmPassword`)"
                    :placeholder="$t(`fieldHint.confirmPassword`)"
                    :rules="validator.confirmPasswordRules(registerObj.password, confirmNewPassword)"
                    :allowShowPassword="false"
                    type="password"
                    prependInnerIcon="mdi-lock-outline"
                  ></app-form-field>
                </v-card-text>
              </v-form>

              <v-form ref="registerForm2">
                <v-row no-gutters>
                  <v-col cols="12">
                    <app-form-field
                      :enabledLabel="true"
                      v-model.trim="registerObj.currency"
                      :label="$t(`field.currency`)"
                      type="select"
                      :disabled="true"
                      height:30
                      :rules="validator.dropDownListRules()"
                      :items="currencyList"
                      class="registerformfield black--text"
                    ></app-form-field>
                  </v-col>
                </v-row>
                <v-card-text class="ma-0 pa-0">
                  <label class="input-field-label ma-0 pb-2 text-capitalize d-block">
                    {{ $t(`field.phoneNumber`) }}
                    <span class="red--text ml-1">*</span>
                  </label>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-select
                        elevation="2"
                        v-model.trim="registerObj.dialCode"
                        class="registerformfield input-field mr-2 black--text"
                        item-text="text"
                        item-value="value"
                        :items="mobileNumberPrefixList"
                        dense
                        outlined
                        :readonly="true"
                      >
                        <template #prepend-inner>
                          <v-btn icon small>
                            <v-avatar size="25" class="language-button">
                              <img width="25" height="25" :src="`/static/image/country/${currentCurrency}.svg`" alt="nagad88 cricket exchange" />
                            </v-avatar>
                          </v-btn>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="6" ref="phonenumber">
                      <app-form-field
                        class="registerformfield 14fontsize"
                        :enabledLabel="false"
                        v-model.trim="registerObj.mobileNumber"
                        :rules="validator.mobileNumberRules(registerObj.language)"
                        :placeholder="$t(`fieldHint.phoneNumberr`)"
                        :isOptional="true"
                      ></app-form-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
              <v-form ref="registerForm3">
                <v-card-text class="ma-0 pa-0">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <app-form-field
                        class="registerformfield"
                        :enabledLabel="true"
                        v-model.trim="registerObj.email"
                        :label="$t(`field.email`)"
                        :isOptional="true"
                        :placeholder="$t(`fieldHint.fillEmail`)"
                        :rules="validator.emailRules()"
                      ></app-form-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <app-form-field
                        class="registerformfield specialgrey"
                        :enabledLabel="true"
                        :label="$t(`field.referralCode`)"
                        readonly
                        :rules="validator.referralCodeRules()"
                        :isOptional="true"
                        v-model.trim="registerObj.referralCode"
                      ></app-form-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-checkbox class="mt-0 pt-0" :rules="validator.requiredRules()">
                        <template v-slot:label>
                          <p class="caption pt-0 mb-0">
                            <label style="font-size: 14px !important;">{{ $t(`message.registerDisclaimer_1`) }}</label>
                            <label class="secondary&#45;&#45;text font-weight-bold" style="cursor: pointer; font-size: 14px !important;" @click="openRegisterTncDialog">
                              {{ $t(`message.registerDisclaimer_2`) }}
                            </label>
                          </p>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="d-flex justify-center align-center">
                    <v-btn width="100%" class="circle-button text-capitalize" style="color: #fff; background: transparent linear-gradient(180deg, #FFAB2D 0%, #FF7700 100%);" @click="this.register">
                      {{ $t(`button.joinNow`) }}
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-row>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-close'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="mdi-close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import stringFormat from 'string-format'
import { formValidator, uiHelper, locale } from '@/util'
import {
  MEMBER_CHECK_USERNAME,
  MEMBER_RESET_CHECK_USERNAME,
  MEMBER_REGISTER,
  MEMBER_SMS_REQUEST,
  MEMBER_SMS_VERIFY,
  MEMBER_GET_SMS_REQUIREMENT,
  MEMBER_RESET_SMS_VERIFICATION
} from '@/store/member.module'
import { SESSION, SHARED } from '@/constants/constants'
import { errorCodeHelper } from '@/util/error-code-helper'

export default {
  name: 'appRegister',
  mounted() {
    this.$refs.registerForm1.reset()
    this.$refs.registerForm3.reset()
    this.checkAffiliateReferralCode()
    this.checkPhoneVerificationRequirements()
  },
  props: {
    openLoginDialog: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  created() {
    this.metaTag()

    try {
      srtmCommands.push({
        event: 'track.user.registration',
        payload: {
          action: 'start'
        }
      })
    } catch (err) {
      console.log(err)
    }
  },
  data: () => ({
    currentCurrency: uiHelper.getCurrency(),
    currentLanguage: uiHelper.getLanguage(),
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    registerTncUrl: '',
    registerTncDialog: {
      show: false,
      title: locale.getMessage(`label.registerAccountAgreement`)
    },
    validator: formValidator,
    stringFormat: stringFormat,
    isReferralCodeReadonly: false,
    confirmNewPassword: '',
    mobileNumberPrefixList: [
      {
        text: '+91',
        value: '91',
        currency: 'INR',
        lang: 'id'
      },
      {
        text: '+880',
        value: '880',
        currency: 'BDT',
        lang: 'bd'
      }
    ],
    currencyList: ['INR', 'BDT'],
    registerObj: {
      memberCode: '',
      password: '',
      currency: SHARED.DEFAULT_CURRENCY,
      email: '',
      dialCode: uiHelper.getDialCode(),
      mobileNumber: '',
      verificationCode: '',
      line: '',
      referralCode: '',
      isEarlyBird: '0',
      domain: '',
      language: SHARED.DEFAULT_LANGUAGE,
      platform: '',
      fp: '',
      agent_team: '',
      utm_source: uiHelper.getCookie(SESSION.UTM_SOURCE),
      utm_medium: uiHelper.getCookie(SESSION.UTM_MEDIUM),
      utm_campaign: uiHelper.getCookie(SESSION.UTM_CAMPAIGN),
      s2: uiHelper.getCookie(SESSION.UTM_S2),
      pid: uiHelper.getCookie(SESSION.PID),
      c_id: uiHelper.getCookie(SESSION.CLICKID),
      stag: uiHelper.getCookie(SESSION.STAG),
      tracking_link: uiHelper.getCookie(SESSION.TRACKING_LINK)
    },
    confirmedPassword: '',
    step: 1,
    regSteps: 2,
    banners: ['/static/image/register/'],
    errorMsg: undefined,
    userNameErrorMsg: undefined,
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  computed: {
    smsRequired() {
      return this.$store.state.member.smsVerificationRequired.required
    },
    smsVerificationSent() {
      return this.$store.state.member.smsVerificationSent.success
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.success
    },
    smsVerificationError() {
      return this.$store.state.member.smsVerificationResult.message
    },
    smsRequestError() {
      return this.$store.state.member.smsVerificationSent.message
    },
    smsRequestAvailable() {
      return this.$store.state.member.smsVerificationSent.countDownTimer <= 0
    },
    smsCountdownTimer() {
      return this.$store.state.member.smsVerificationSent.countDownTimer.toString()
    },
    checkUsernameResult() {
      return this.$store.state.member.checkUsernameResult.completed
    },
    watchCurrency() {
      return this.registerObj.currency
    }
  },
  watch: {
    watchCurrency() {
      const selected = this.mobileNumberPrefixList.find(x => x.currency == this.registerObj.currency)
      if (selected) {
        this.registerObj.dialCode = selected.value
      }
    },
    smsRequestError() {
      if (this.$store.state.member.smsVerificationSent.complete && !this.$store.state.member.smsVerificationSent.success) {
        this.errorDialog(this.$store.state.member.smsVerificationSent.code)
      }
    },
    checkUsernameResult() {
      if (this.$store.state.member.checkUsernameResult.completed) {
        if (this.$store.state.member.checkUsernameResult.success) {
          this.userNameErrorMsg = undefined
        } else {
          if (this.$store.state.member.checkUsernameResult.code == -2) {
            this.userNameErrorMsg = locale.getMessage(`fieldErrorMessage.usernameExceedLength`)
          } else if (this.$store.state.member.checkUsernameResult.code == -1) {
            this.userNameErrorMsg = locale.getMessage(`fieldErrorMessage.usernameUsed`)
          }
        }
        this.$store.dispatch(`${MEMBER_RESET_CHECK_USERNAME}`)
      }
    }
  },
  methods: {
    metaTag() {
      uiHelper.setMetaTag('url', 'https://www.krikya.group/register/')
      uiHelper.setTitle(`${locale.getMessage('meta.register_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.register_description')}`)
      uiHelper.setMetaTag('keywords', `${locale.getMessage('meta.register_keyword')}`)
      uiHelper.setCanonical('https://www.krikya.group/register/')
      const origin = window.location.origin
      let setLink = `${origin}/${this.currentLanguage === 'en' ? 'en-BD' : 'bn-BD'}`
      uiHelper.setCanonical(setLink)
    },
    checkUsername() {
      if (this.registerObj.memberCode.trim() !== '') {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_CHECK_USERNAME}`, {
          registerObj
        })
      }
    },
    errorDialog(errorCode) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.register`)
      dialog.dialogXButton = null
      dialog.message.push(errorCodeHelper.getErrorCodeDesc(errorCode))
      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      this.$eventHub.$emit('close-page-dialog', true)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    checkPhoneVerificationRequirements() {
      this.$store.dispatch(`${MEMBER_GET_SMS_REQUIREMENT}`)
    },
    openRegisterTncDialog() {
      this.registerTncUrl = `${process.env.BASE_URL}static/html/other/${uiHelper.getLanguage()}/register-tnc.html`
      this.registerTncDialog.show = true
      this.registerTncDialog.dialogXButton = this.closePromotionTncDialog
    },
    closeRegisterTncDialog() {
      this.registerTncDialog.show = false
    },
    openLogin() {
      this.openLoginDialog()
    },

    register() {
      // if (this.smsRequired && !this.smsVerificationSent && !this.smsVerificationResult.success) {
      //   this.requestSmsVerificationCode()
      //   return
      // }
      if (this.$refs.registerForm2.validate() && this.$refs.registerForm3.validate()) {
        let jObj = this
        this.registerObj.platform = uiHelper.getPlatform()
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let existFp = typeof uiHelper.getCookie('fp_visitor') != 'undefined' && uiHelper.getCookie('fp_visitor') != null && uiHelper.getCookie('fp_visitor') != ''
        if (existFp) {
          jObj.registerObj.fp = uiHelper.getCookie('fp_visitor')
          let registerObj = this.registerObj
          this.$store.dispatch(`${MEMBER_REGISTER}`, {
            registerObj
          })
        } else {
          try {
            jObj.registerObj.fp = uiHelper.getFingerprints()
            if (jObj.registerObj.fp != '' && jObj.registerObj.fp != null) {
              uiHelper.setCookie('fp_visitor', jObj.registerObj.fp, 30)
              let registerObj = jObj.registerObj
              this.$store.dispatch(`${MEMBER_REGISTER}`, {
                registerObj
              })
            }
          } catch (err) {
            log(err)
            let registerObj = this.registerObj
            this.$store.dispatch(`${MEMBER_REGISTER}`, {
              registerObj
            })
          }
          //this.registerObj.currency = uiHelper.getCurrency()
        }
      }
    },
    formatSeconds(secs) {
      const pad = n => (n < 10 ? `0${n}` : n)

      const h = Math.floor(secs / 3600)
      const m = Math.floor(secs / 60) - h * 60
      const s = Math.floor(secs - h * 3600 - m * 60)

      return `${pad(m)}:${pad(s)}`
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      if (this.$refs.registerForm2.validate()) {
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
      }
    },
    async verifySmsCode() {
      if (this.$refs.registerForm.validate()) {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
          registerObj
        })
      }
    },
    checkAffiliateReferralCode() {
      let referCode = uiHelper.getCookie(SESSION.REFER_CODE)
      let afCode = uiHelper.getCookie(SESSION.AFF_CODE)
      if (afCode != null && afCode != '') {
        this.registerObj.agent_team = afCode
        this.isReferralCodeReadonly = true
      } else if (referCode != null && referCode != '') {
        this.registerObj.referralCode = referCode
        this.isReferralCodeReadonly = true
      } else {
        this.registerObj.referralCode = ''
        this.isReferralCodeReadonly = false
      }
    },
    nextPage() {
      if (this.$refs.registerForm1.validate() && !this.userNameErrorMsg) {
        this.step++
      }
    },
    previousPage() {
      this.step--
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    }
  }
}
</script>

<style lang="scss">
.registerformfield .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  font-size: 14px;
}

.specialgrey .input-field-login .v-input__control .v-input__slot {
  background-color: rgba(0, 0, 0, 0.1);
}

.registerformfield .input-field-login .v-input__control .v-input__slot {
  min-height: 30px;
  height: 30px;
}

.registerformfield .v-input__control .v-input__slot {
  min-height: 30px !important;
  height: 30px !important;
}

.register_panel-af {
  justify-content: center;

  .register-wrapper-af {
    max-width: 1240px;
    margin: auto;
    //border: 8px solid var(--v-primary-base);
    display: flex;
    justify-content: space-between;
    gap: 1em;

    .sign-up-title {
      justify-content: center;
      color: var(--v-secondary-base);
      border-bottom: 1px solid var(--v-secondary-base);
      padding-bottom: 0.5em;
      margin-bottom: 1.5em;
    }

    .circle-button {
      padding: 12px;
      border-radius: 4px;
      height: 45px;
      background-color: var(--v-primary-lighten1);
    }

    .circle-button:hover {
      background-color: var(--v-primary-base);
    }

    .otp-button {
      background-color: var(--v-primary-base);
      border-radius: 4px;
    }

    .v-select__selection {
      color: #000000;
    }

    .input-field .v-icon,
    .input-field .v-input__icon {
      color: var(--v-primary-base);
    }

    .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
      margin-top: 0px;
    }
  }
}

@media (max-width: 599px) {
  .register_panel-af {
    background-color: #ffffff;

    .register-wrapper-af {
      border: unset;
    }
  }
}

@media (max-width: 440px) {
  .input-field .v-input__slot .v-select__slot .v-select__selections .v-select__selection--comma {
    font-size: 12.5px;
  }
}
</style>
