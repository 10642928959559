<template>
  <v-row no-gutters>
    <!-- <v-col cols="12" class="text-center">
      <input type="button" value="Say hello" @click="showAndroidToast()" style="width: 80%; height: 40px; border: 1px solid black; margin: 12px;" />
    </v-col> -->
    <v-col cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'login_panel px-3' : 'login_panel px-4 py-4'">
      <WatchTutorialVideo style="margin: 0 0 20px" :label="$t(`label.howToResetPassword`)" video-url="https://krikyabangladesh.wistia.com/medias/6nwhxiq7nl"/>
      <v-form ref="loginForm">
        <app-form-field
          :enabledLabel="true"
          v-model.trim="loginObj.memberCode"
          :label="$t(`field.username`)"
          :placeholder="$t(`fieldHint.fillUpHere`)"
          :rules="validator.usernameRules()"
          prependInnerIcon="mdi-account-outline"
        ></app-form-field>
        <app-form-field
          :enabledLabel="true"
          v-model.trim="loginObj.password"
          :label="$t(`field.password`)"
          :placeholder="$t(`fieldHint.fillUpHere`)"
          :rules="validator.passwordRules()"
          :allowShowPassword="false"
          type="password"
          prependInnerIcon="mdi-lock-outline"
        ></app-form-field>
        <v-row no-gutters>
          <v-col cols="12" class="text-right forgot-password">
            <v-btn text height="auto" @click="openForgotPasswordDialog" class="text-capitalize pr-0">
              {{ $t(`label.forgotPassword`) }}
            </v-btn>
          </v-col>
        </v-row>
        <!-- <app-button :action="this.login" :title="$t(`button.login`)"></app-button> -->
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn height="auto" @click="this.login" class="button-primary full-width font-weight-bold">
              {{ $t(`button.login`) }}
            </v-btn>
            <!-- <button-primary
                :title="$t('button.login')"
                @action="this.login"
                :customClass="'full-width font-weight-bold'">
            </button-primary> -->
          </v-col>
          <v-col cols="12" class="text-center">
            <label v-show="loginErrorMsg" class="red--text subtitle-2">{{ loginErrorMsg }}</label>
          </v-col>
        </v-row>
      </v-form>
      <p class="mt-5 text-left subtitle-2">
        {{ $t(`message.dontHaveAccount`) }}
        <a class="link-underscore" @click="openRegister">{{ $t(`label.signUp`) }}</a>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import stingFormat from 'string-format'
import { formValidator, uiHelper, errorCodeHelper } from '@/util'
import { SHARED } from '@/constants/constants'
import { MEMBER_LOGIN } from '@/store/member.module'
import WatchTutorialVideo from "@/components/WatchTutorialVideo.vue";

export default {
  name: 'appLogin',
  components: {WatchTutorialVideo},
  mounted() {
    this.$refs.loginForm.reset()
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
      default: () => {}
    },
    openRegisterDialog: {
      type: Function,
      required: true,
      default: () => {}
    },
    openForgotPasswordDialog: {
      type: Function,
      required: true,
      default: () => {}
    },
    loginErrorMsg: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: () => ({
    validator: formValidator,
    stingFormat: stingFormat,
    retainSignIn: false,
    loginObj: {
      memberCode: '',
      password: '',
      domain: '',
      platform: '',
      option: SHARED.DEFAULT_LOGIN_PASSWORD_METHOD
    }
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    isLoggedIn() {
      this.$refs.loginForm.reset()
    }
  },
  methods: {
    // showAndroidToast() {
    //   // AndroidFunction.isAndroid();
    //   // AndroidFunction.isMobile();
    //     // Android.showToast(toast);
    //     // Android.biometric();
    // },
    openRegister() {
      this.$refs.loginForm.reset()
      this.openRegisterDialog()
    },
    login() {
      if (this.$refs.loginForm.validate()) {
        let jObj = this
        this.loginObj.domain = uiHelper.getHostname()
        this.loginObj.platform = uiHelper.getPlatform()
        this.loginObj.language = uiHelper.getLanguage()
        let existFp = typeof uiHelper.getCookie('fp_visitor') != 'undefined' && uiHelper.getCookie('fp_visitor') != null && uiHelper.getCookie('fp_visitor') != ''
        if (existFp) {
          jObj.loginObj.fp = uiHelper.getCookie('fp_visitor')
          let loginObj = this.loginObj
          this.$store.dispatch(`${MEMBER_LOGIN}`, {
            loginObj
          })
        } else {
          try {
            jObj.loginObj.fp = uiHelper.getFingerprints()
            if (jObj.loginObj.fp != '' && jObj.loginObj.fp != null) {
              uiHelper.setCookie('fp_visitor', jObj.loginObj.fp, 30)
              let loginObj = jObj.loginObj
              this.$store.dispatch(`${MEMBER_LOGIN}`, {
                loginObj
              })
            }
          } catch (err) {
            console.log(err)
            let loginObj = this.loginObj
            this.$store.dispatch(`${MEMBER_LOGIN}`, {
              loginObj
            })
          }
        }
        // let loginObj = this.loginObj
        // this.$store.dispatch(`${MEMBER_LOGIN}`, {
        //   loginObj
        // })
      }
    }
  }
}
</script>

<style lang="scss">
.login_panel {
  color: #000000;
  background-color: #ffffff;
  padding: 12px 16px !important;

  .dialog-header {
    background-color: var(--v-primary-base);
  }

  .link-underscore:hover {
    text-decoration: underline;
    color: var(--v-primary-base);
  }

  .forgot-password {
    margin-bottom: 12px;

    .theme--dark.v-btn {
      color: #000000;
    }

    .v-btn:hover {
      text-decoration: underline;
      color: var(--v-primary-base);
      cursor: pointer;
    }

    .v-btn::before {
      background-color: unset;
    }
  }

  .watch-video-container {
    .watch-video-label {
      width: calc(100% - 128px);
      font-size: 13px;
    }

    .watch-video-button {
      width: 128px;
      button {
        width: 120px;
        font-size: 12px !important;
      }
    }

    .watch-video-close {
      right: -4px;
    }
  }
}
</style>
